import React from 'react';
import Grid from '@material-ui/core/Grid';
import Separator from 'src/products/core-prospect/components/Panel/Regular/Element/Separator/component';

interface Detail {
  title: string;
  content: string;
}

interface DatasetSummaryDetailsProps {
  details?: Detail[];
}

const DatasetSummaryDetails: React.FC<DatasetSummaryDetailsProps> = ({
  details = [],
}) => {
  return (
    <div id='js-data-connections-dataset-details-summary-details'>
      {details.map((detail, index) => {
        return (
          <div key={index}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div style={{ fontWeight: 'bold' }}>{detail.title}</div>
              </Grid>
              <Grid item xs={9}>
                <div>{detail.content}</div>
              </Grid>
            </Grid>
            <Separator height={0.8} />
          </div>
        );
      })}
    </div>
  );
};

DatasetSummaryDetails.displayName = 'DatasetSummaryDetails';

export default DatasetSummaryDetails;
