import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

interface DatasetHeaderProps {
  isButtonDisabled: boolean;
  name: string;
  imgUrl?: string;
  partner: string;
  buttonText: string;
  buttonAction: () => void;
}

const useStyles = makeStyles(() => ({
  container: {
    gridArea: 'header',
    display: 'grid',
    gridTemplateAreas: `
    "img . name button"
    "img . . ."
    "img . partner ."
    `,
    gridTemplateColumns: '90px 24px auto auto',
    gridTemplateRows: 'auto 3px auto',
  },
  image: {
    gridArea: 'img',
    width: '90px',
    height: '90px',
  },
  name: {
    gridArea: 'name',
  },
  wishlistButton: {
    gridArea: 'button',
    textTransform: 'none',
    fontWeight: 'bold',
    alignSelf: 'start',
    justifySelf: 'end',
  },
  partner: {
    gridArea: 'partner',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 500,
    color: '#212121',
  },
}));

export const DatasetHeader: React.FC<DatasetHeaderProps> = ({
  name,
  imgUrl,
  partner,
  buttonText,
  buttonAction,
  isButtonDisabled,
}) => {
  const classes = useStyles();
  return (
    <div
      id='js-data-connections-dataset-details-header'
      className={classes.container}
    >
      <img className={classes.image} src={imgUrl} />
      <Typography variant='h1' className={classes.name}>
        {name}
      </Typography>
      <Typography className={classes.partner}>by {partner}</Typography>
      <Button
        disabled={isButtonDisabled}
        className={classes.wishlistButton}
        size='small'
        variant='contained'
        color='primary'
        onClick={buttonAction}
      >
        {buttonText}
      </Button>
    </div>
  );
};

DatasetHeader.displayName = 'DatasetHeader';
