import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/styles';
import Tabs from 'src/products/data-connections/components/Tabs';

import { DatasetSummary } from './DatasetSummary/index';
import { DatasetDashboard } from './DatasetDashboard';
import * as T from 'src/products/data-connections/types';
import { LinearProgress } from '@material-ui/core';

interface DatasetContentsProps {
  dictionary: T.CSV | null | undefined;
  preview: T.CSV | null;
  dashboard?: T.Dashboard;
}

const useStyles = makeStyles(() => ({
  container: {
    gridArea: 'content',
  },
}));

export const DatasetContents: React.FC<DatasetContentsProps> = ({
  dictionary,
  preview,
  dashboard,
}) => {
  const classes = useStyles();
  return (
    <div
      id='js-data-connections-dataset-details-contents'
      className={classes.container}
    >
      <Tabs.Container>
        <Tabs.Tab label='Summary'>
          <DatasetSummary dictionary={dictionary} preview={preview} />
        </Tabs.Tab>
        {dashboard && (
          <Tabs.Tab label='Reporting and Insights'>
            <Suspense fallback={<LinearProgress />}>
              <DatasetDashboard dashboard={dashboard} />
            </Suspense>
          </Tabs.Tab>
        )}
      </Tabs.Container>
    </div>
  );
};

DatasetContents.displayName = 'DatasetContents';
