import axios from 'axios';
import { atom, selector, selectorFamily } from 'recoil';
import * as T from 'src/products/data-connections/types';

const PREFIX = 'DATASET-LIST/';

export const ALL_PARTNERS = 'all partners';
export const LIGHTNING_CONNECTION = 'lightning connection';
export const NOT_LIGHTNING_CONNECTION = 'not lightning connection';

export const datasetSearchAtom = atom<string>({
  key: PREFIX + 'DATASET_SEARCH_ATOM',
  default: '',
});

export const selectedPartnerAtom = atom<string>({
  key: PREFIX + 'SELECTED-PARTNER-ATOM',
  default: ALL_PARTNERS,
});

export const selectedLightingConnectionAtom = atom<string>({
  key: PREFIX + 'SELECTED-LIGHTNING-CONNECTION',
  default: LIGHTNING_CONNECTION,
});

export const offsetAtom = atom<number>({
  key: PREFIX + 'OFFSET-ATOM',
  default: 0,
});

export const limitAtom = atom<number>({
  key: PREFIX + 'LIMIT-ATOM',
  default: 100,
});

export const availablePartnersSelector = selector<T.Organization[]>({
  key: PREFIX + 'AVAILABLE-PARTNERS-SELECTOR',
  get: async () => {
    const url = '/api/v1/organizations/with-datasets';
    const response = await axios.get<T.Organization[]>(url);
    return response.data;
  },
});

interface DatasetListParams {
  search: string;
  organizationId: string;
  limit?: number;
  offset?: number;
  isLightningConnection?: string;
}

const datasetListGetterSelector = selectorFamily<
  T.Dataset[],
  Readonly<DatasetListParams>
>({
  key: PREFIX + 'DATASET-LIST-GETTER-SELECTOR',
  get: (params) => async () => {
    const query = new URLSearchParams();
    if (params.organizationId) {
      query.set('organization_id', String(params.organizationId));
    }
    if (params.search) {
      query.set('name', params.search);
    }
    if (params.offset) {
      query.set('offset', String(params.offset));
    }
    if (params.limit) {
      query.set('limit', String(params.limit));
    }
    if (params.isLightningConnection) {
      const isLightningConnection =
        params.isLightningConnection === LIGHTNING_CONNECTION ? true : false;
      query.set('isLightningConnection', String(isLightningConnection));
    }

    const url = `/api/v1/organizations/datasets?${query.toString()}`;
    const response = await axios.get<T.Dataset[]>(url);
    return response.data;
  },
});

export const datasetListSelector = selector<T.Dataset[]>({
  key: PREFIX + 'DATASET-LIST-SELECTOR',
  get: ({ get }) => {
    const search = get(datasetSearchAtom);
    const isLightningConnection = get(selectedLightingConnectionAtom);
    const organizationId = get(selectedPartnerAtom);
    const offset = get(offsetAtom);
    const limit = get(limitAtom);

    return get(
      datasetListGetterSelector({
        search,
        isLightningConnection,
        organizationId: organizationId === ALL_PARTNERS ? '' : organizationId,
        offset,
        limit,
      })
    );
  },
});
