import * as React from 'react';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  DATA_DICTIONARY_VIEW,
  DATA_SUMMARY_VIEW,
} from 'src/products/data-connections/pages/DatasetDetails/recoil';
import { useRecoilState } from 'recoil';
import * as atoms from '../recoil';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  labelLeft: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  labelRight: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.primary.main,
    marginLeft: 10,
    lineHeight: 1.5,
  },
}));

const DatasetSwitch: React.FC = () => {
  const [selectedDataView, setSelectedDataViewState] = useRecoilState(
    atoms.dataViewState
  );
  const classes = useStyles();

  const toggleSelectedDataView = (e) => {
    const lastValue = e.target.value;
    const newValue =
      lastValue === DATA_DICTIONARY_VIEW
        ? DATA_SUMMARY_VIEW
        : DATA_DICTIONARY_VIEW;
    if (newValue === DATA_DICTIONARY_VIEW) {
      window.analytics?.track('Data Sample Toggle Clicked');
    }
    setSelectedDataViewState(newValue);
  };

  return (
    <div className={classes.container}>
      <FormControl>
        <FormGroup aria-label='position' row>
          <FormControlLabel
            value={selectedDataView}
            control={
              <Switch
                id='js-data-connections-dataset-summary-dataset-switch'
                checked={selectedDataView === DATA_SUMMARY_VIEW}
                onChange={toggleSelectedDataView}
                color='primary'
              />
            }
            label={<div className={classes.labelLeft}>Dictionary</div>}
            labelPlacement='start'
          />
        </FormGroup>
      </FormControl>
      <Typography className={classes.labelRight}>Data Sample</Typography>
    </div>
  );
};

DatasetSwitch.displayName = 'DatasetSwitch';

export default DatasetSwitch;
