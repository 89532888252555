import { Tabs, Tab } from '@material-ui/core';
import React from 'react';

const TabPanel: React.FC<{ selected: boolean }> = ({ selected, children }) => {
  if (!selected) {
    return null;
  }
  return <>{children}</>;
};

interface TabProps {
  label: string;
}

const TabComponent: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>;
};

interface ContainerProps {
  children: (ReturnType<typeof TabComponent> | null | undefined)[];
}

const Container: React.FC<ContainerProps> = ({ children }) => {
  const [selected, setSelected] = React.useState(0);

  return (
    <>
      <Tabs value={selected} onChange={(_, value) => setSelected(value)}>
        {React.Children.map(
          children,
          (child) => child && <Tab label={child.props.label} />
        )}
      </Tabs>
      {React.Children.map(
        children,
        (child, index) =>
          child && <TabPanel selected={selected === index}>{child}</TabPanel>
      )}
    </>
  );
};

export default {
  Container,
  Tab: TabComponent,
};
