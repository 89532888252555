import React from 'react';
import { InputAdornment, MenuItem, Select, TextField } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import {
  ALL_PARTNERS,
  LIGHTNING_CONNECTION,
  NOT_LIGHTNING_CONNECTION,
} from '../recoil';
import * as T from 'src/products/data-connections/types';

interface DatasetFilterProps {
  search: string;
  setSearch: (search: string) => void;
  availablePartners: T.Organization[];
  selectedPartner: string;
  setPartner: (partner: string) => void;
  lightingConnection: string;
  setLightingConnection: (lightingConnection: string) => void;
  // availableDataTypes: string[];
  // selectedDataType: string;
  // setDataType: (dataType: string) => void;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: 12,
  },
  search: { fontSize: 20, color: '#757575' },
  input: {
    width: '360px',
  },
}));

export const DatasetFilter: React.FC<DatasetFilterProps> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <TextField
        id='js-data-connections-list-search-input'
        className={classes.input}
        placeholder='Search dataset'
        value={props.search}
        onChange={(e) => props.setSearch(e.target.value)}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position='start'>
              <Search className={classes.search} />
            </InputAdornment>
          ),
        }}
      />
      <Select
        id='js-data-connections-data-partner-select'
        disableUnderline
        value={props.selectedPartner}
        onChange={(e) => props.setPartner(e.target.value as string)}
      >
        <MenuItem
          value={ALL_PARTNERS}
          id='js-data-connections-data-partner-menu-item-all-partners'
        >
          All Partners
        </MenuItem>
        {props.availablePartners.map((_partner) => (
          <MenuItem key={_partner.id} value={_partner.id}>
            {_partner.name}
          </MenuItem>
        ))}
      </Select>
      <Select
        id='js-data-connections-lighting-connections-select'
        disableUnderline
        value={props.lightingConnection}
        onChange={(e) => props.setLightingConnection(e.target.value as string)}
      >
        <MenuItem
          id='js-data-connections-data-partner-menu-item-lightning-connections'
          value={LIGHTNING_CONNECTION}
        >
          Lightning Connection
        </MenuItem>
        <MenuItem
          id='js-data-connections-data-partner-menu-item-all-datasets'
          value={NOT_LIGHTNING_CONNECTION}
        >
          All Datasets
        </MenuItem>
      </Select>
    </div>
  );
};

DatasetFilter.displayName = 'DatasetFilter';
