import React from 'react';
import muiThemeSettings from 'src/const/theme';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: 'Montserrat',
  h1: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 48,
  },
  h3: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: 32,
  },
  h4: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: 24,
    marginTop: 44,
    marginBottom: 20,
  },
  body1: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 14,
  },
};

const theme = createMuiTheme({
  ...muiThemeSettings,
  typography: {
    ...muiThemeSettings.typography,
    ...typography,
  },
  overrides: {
    MuiChip: {
      root: {
        padding: '5px 12px',
        borderRadius: '14.5px',
        border: 'solid 1px #bdbdbd',
        backgroundColor: '#ffffff',
      },
      label: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 500,
        color: '#616161',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#9e9e9e',
        '&$selected': {
          color: muiThemeSettings.palette?.primary?.main,
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: muiThemeSettings.palette?.primary?.main,
        height: '5px',
        borderRadius: '5px 5px 0 0',
      },
    },
    MuiTableHead: {
      root: {
        borderBottom: '2px solid #bdbdbd',
      },
    },
    MuiInput: {
      root: {
        borderRadius: 10,
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
        padding: '4px 15px',
        backgroundColor: '#fff',
      },
    },
  },
});

export default <P = Record<string, unknown>>(component: React.ElementType) =>
  (props: P) => {
    return React.createElement(ThemeProvider, {
      theme,
      children: React.createElement(component, props),
    });
  };
