import React from 'react';
import DatasetSwitch from '../DatasetSwitch';
import SelectWithItems from 'src/components/SelectWithItems';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as atoms from '../../recoil';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { useParams } from 'react-router';

interface DataDictionarySampleHeaderProps {
  search: string;
  setSearch: (search: string) => void;
}

const useStyles = makeStyles(() => ({
  search: { fontSize: 20, color: '#757575' },
  input: {
    width: '360px',
    marginBottom: 20,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputSwitchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectContainer: {
    width: 300,
  },
}));

const DataDictionarySampleHeader: React.FC<DataDictionarySampleHeaderProps> = ({
  search,
  setSearch,
}) => {
  const classes = useStyles();
  const params = useParams<{ dataset_id: string }>();

  const menuItems = useRecoilValue(atoms.datatableSelector(params.dataset_id));
  const [selectedDatatable, setSelectedDatatable] = useRecoilState(
    atoms.selectedDatatableState
  );
  return (
    <div
      id='js-data-connections-datasets-detail-data-dictionary-sample-header'
      className={classes.container}
    >
      <div className={classes.inputSwitchContainer}>
        <div
          id='js-data-connections-datasets-detail-data-dictionary-select-container'
          className={classes.selectContainer}
        >
          <SelectWithItems
            menuItems={menuItems}
            changeHandler={setSelectedDatatable}
            index={0}
            fieldName={'testing'}
            value={selectedDatatable}
            label={undefined}
            size={'small'}
            variant={'standard'}
            disableUnderline
          />
        </div>
        <DatasetSwitch />
      </div>
      <TextField
        className={classes.input}
        placeholder='Search field'
        value={search}
        onChange={(e) => {
          window.analytics?.track('Search field typed');
          setSearch(e.target.value.toLowerCase());
        }}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position='start'>
              <Search className={classes.search} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

DataDictionarySampleHeader.displayName = 'DataDictionarySampleHeader';

export default DataDictionarySampleHeader;
