import { LinearProgress, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import withTheme from '../../theme';
import * as Components from './components';
import * as atoms from './recoil';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useGreyBody } from '../../utils';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles(() => ({
  container: {
    margin: '0 auto',
    width: 'calc(100% - 166px)',
    display: 'grid',
    paddingTop: 26,
    gap: 25,
  },
  header: {
    display: 'flex',
    gap: 12,
    alignItems: 'flex-end',
  },
}));

const DatasetList: React.FC = () => {
  const classes = useStyles();
  const [search, setSearch] = useRecoilState(atoms.datasetSearchAtom);
  const [partner, setPartner] = useRecoilState(atoms.selectedPartnerAtom);
  const [lightingConnection, setLightingConnection] = useRecoilState(
    atoms.selectedLightingConnectionAtom
  );
  const availablePartners = useRecoilValue(atoms.availablePartnersSelector);

  useGreyBody();
  return (
    <div className={classes.container}>
      <div>
        <Typography variant='h3'>Lightning Connections</Typography>
        <Typography style={{ color: grey[700] }} variant='subtitle2'>
          Cherre Lightning Connections enable customers to add unique datasets
          to their Cherre data analytics warehouse within hours.
        </Typography>
      </div>
      <Components.DatasetFilter
        search={search}
        setSearch={setSearch}
        availablePartners={availablePartners}
        selectedPartner={partner}
        setPartner={setPartner}
        lightingConnection={lightingConnection}
        setLightingConnection={setLightingConnection}
      />
      <React.Suspense fallback={<LinearProgress />}>
        <Components.DatasetsWishlist />
        <Components.DatasetCardList />
      </React.Suspense>
    </div>
  );
};

DatasetList.displayName = 'DatasetList';

export default withTheme(DatasetList);
