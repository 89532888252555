import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import * as T from 'src/products/data-connections/types';
import * as atoms from '../recoil';
import { useRecoilValue } from 'recoil';
import { useAnalyticsEffect } from 'src/products/data-connections/utils';
import { stripHtml } from 'string-strip-html';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(265px, 1fr))',
    gap: '20px',
    paddingBottom: '50px',
  },
  card: {
    backgroundColor: '#fff',
    cursor: 'pointer',
    height: '112px',
    display: 'grid',
    padding: '10px 30px 11px 16px',
    borderRadius: '6px',
    boxShadow: '0 2px 5px 3px rgba(0, 0, 0, 0.1)',
    gridTemplateAreas: `
        "img . title"
        "img . description"
        ". . description"
        "partner partner partner"`,
    gridTemplateColumns: '36px 16px auto',
    gridTemplateRows: 'auto auto 1fr auto',
  },
  image: {
    gridArea: 'img',
    width: '36px',
    height: '36px',
  },
  title: {
    gridArea: 'title',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    color: '#212121',
  },
  description: {
    gridArea: 'description',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 500,
    color: '#616161',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  partner: {
    gridArea: 'partner',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontWeight: 500,
    color: '#616161',
  },
}));

export const DatasetCard = React.memo<T.Dataset>((props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const parseDescription = useMemo(
    () => stripHtml(props.description).result,
    [props.description]
  );

  return (
    <div
      id={`js-data-connections-dataset-card-${props.index}`}
      key={props.name}
      onClick={() => dispatch(push(`/data-connections/details/${props.id}`))}
      className={classes.card}
    >
      <img src={props.organization.imageUrl} className={classes.image} />
      <Typography className={classes.title}>{props.name}</Typography>
      <Typography className={classes.description}>
        {parseDescription}
      </Typography>
      <Typography className={classes.partner}>
        by {props.organization.name}
      </Typography>
    </div>
  );
});

DatasetCard.displayName = 'DatasetCard';

export const DatasetCardList: React.FC = () => {
  const classes = useStyles();
  const datasets = useRecoilValue(atoms.datasetListSelector);
  const search = useRecoilValue(atoms.datasetSearchAtom);
  const partnerFilter = useRecoilValue(atoms.selectedPartnerAtom);
  useAnalyticsEffect(
    (analytics) => {
      analytics.track('dataset_searched', {
        textInput: search,
        partnerFilter,
        numberOfResults: datasets.length,
      });
    },
    [datasets, search, partnerFilter]
  );
  return (
    <>
      <Typography variant='body1' style={{ color: '#212121' }}>
        {datasets.length} Data set
        {datasets.length === 1 ? '' : 's'}
      </Typography>
      <div className={classes.container}>
        {datasets.map((dataset, index) => (
          <DatasetCard key={dataset.name} {...dataset} index={index} />
        ))}
      </div>
    </>
  );
};

DatasetCardList.displayName = 'DatasetCardList';
