import { AnalyticsSnippet } from '@segment/analytics-next';
import React from 'react';
import { IS_SEGMENT_TRACKER_ENABLED } from 'src/const';

type EventRegister = (analytics: AnalyticsSnippet) => void;
type EventRegisterCallback<P> = (
  analytics: AnalyticsSnippet
) => (params: P) => void;

export const useAnalyticsEffect = (
  register: EventRegister,
  deps: React.DependencyList = []
) =>
  React.useEffect(() => {
    if (IS_SEGMENT_TRACKER_ENABLED && analytics) {
      register(analytics);
    }
  }, deps);

export const useAnalyticsCallback = <P>(
  register: EventRegisterCallback<P>,
  deps: React.DependencyList = []
) =>
  React.useCallback((params: P) => {
    if (IS_SEGMENT_TRACKER_ENABLED && analytics) {
      register(analytics)(params);
    }
  }, deps);
