import { Paper } from '@material-ui/core';
import React from 'react';
import BasePagedTable from 'src/components/PagedTable';
import { DATA_TABLES_ROW_MINIMUM } from 'src/products/data-connections/utils';

type Columns = {
  dataKey: string;
  label: string;
  width: number;
  isSortable: boolean;
}[];

interface SortBy {
  field: React.Key;
  direction: 'asc' | 'desc';
}

interface PagedTableProps {
  columns: Columns;
  items: Record<string, any>[];
  rowKey: string;
  sortable?: boolean;
  initialRowsPerPage?: number;
}

const PagedTable: React.FC<PagedTableProps> = ({
  columns,
  items,
  rowKey,
  sortable = true,
  initialRowsPerPage = DATA_TABLES_ROW_MINIMUM,
}) => {
  const [sortBy, setSortBy] = React.useState<SortBy>({
    field: columns[0]?.dataKey,
    direction: 'asc',
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

  const [page, setPage] = React.useState(0);
  const _items = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const _order = sortBy.direction === 'asc' ? 1 : -1;
      if (a[sortBy.field] > b[sortBy.field]) {
        return _order;
      }
      if (a[sortBy.field] < b[sortBy.field]) {
        return -_order;
      }
      return 0;
    });
  }, [items, sortBy]);

  const _pagedItems = React.useMemo(() => {
    return _items.slice(rowsPerPage * page, rowsPerPage * (page + 1));
  }, [_items, rowsPerPage, page]);
  const height = (rowsPerPage * 44 || 300) + 103;

  const tableRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => setPage(0), [items]);
  return (
    <Paper>
      <div
        id='js-data-connections-datasets-detail-paged-table-wrapper'
        ref={tableRef}
        style={{ height, display: 'flex', flexDirection: 'column' }}
      >
        <BasePagedTable
          columns={columns}
          items={_pagedItems}
          onChangeOrder={setSortBy}
          stickyLeft={1}
          rowKey={rowKey}
          selection={false}
          orderBy={sortable ? sortBy.field : undefined}
          orderByDirection={sortable ? sortBy.direction : undefined}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(e) => setRowsPerPage(Number(e.target.value))}
          page={page}
          onChangePage={({ index }) => setPage(index)}
          hasNextPage={page + 1 < _items.length / rowsPerPage}
          hasPreviousPage={page > 0}
          totalCount={_items.length}
        />
      </div>
    </Paper>
  );
};

PagedTable.displayName = 'PagedTable';

export default PagedTable;
