import axios from 'axios';
import { atom, atomFamily, selectorFamily } from 'recoil';
import * as T from 'src/products/data-connections/types';
import { refetchableFamily } from 'src/utils/recoil/refetchable';

const PREFIX = 'DATASET-DETAILS/';
export const DATA_DICTIONARY_VIEW = 'DATA_DICTIONARY_VIEW' as const;
export const DATA_SUMMARY_VIEW = 'DATA_SUMMARY_VIEW' as const;
export const SELECTED_DATATABLE = 'SELECTED_DATATABLE' as const;
type DataViewState = 'DATA_DICTIONARY_VIEW' | 'DATA_SUMMARY_VIEW';

export const dataViewState = atom<DataViewState>({
  key: PREFIX + DATA_DICTIONARY_VIEW,
  default: DATA_DICTIONARY_VIEW,
});

export const selectedDatatableState = atom({
  key: PREFIX + SELECTED_DATATABLE,
  default: 0,
});

export const datasetSelector = selectorFamily<T.Dataset, string>({
  key: PREFIX + 'DATASET-SELECTOR',
  get: (id) => async () => {
    const url = `/api/v1/organizations/datasets/${id}`;
    const response = await axios.get<T.Dataset>(url);
    return response.data;
  },
});

export const datasetQuery = selectorFamily({
  key: PREFIX + 'DatasetQuery',
  get: (datasetId) => async () => {
    const url = `/api/v1/organizations/datasets-with-tables/${String(
      datasetId
    )}`;
    const response = await axios.get<T.Dataset>(url);
    return response.data;
  },
});

export const dataViewSelector = selectorFamily({
  key: PREFIX + 'DataViewState',
  get:
    (datasetId) =>
    ({ get }) => {
      const _dataViewState = get(dataViewState);
      const _selectedDatatableState = get(selectedDatatableState);
      const dataset = get(datasetQuery(datasetId));
      const datatable = dataset?.datatables
        ? dataset?.datatables[_selectedDatatableState]
        : undefined;

      switch (_dataViewState) {
        case DATA_DICTIONARY_VIEW:
          return datatable?.dataDictionary;
        case DATA_SUMMARY_VIEW:
          return datatable?.dataPreview;
        default:
          return datatable?.dataDictionary;
      }
    },
});

export const datatableSelector = selectorFamily({
  key: PREFIX + 'DataTablesState',
  get:
    (datasetId) =>
    ({ get }) => {
      const dataset = get(datasetQuery(datasetId));
      const { datatables } = dataset;

      const menuItems = (datatables || []).map((datatable, index) => {
        return {
          value: index,
          label: datatable.name,
        };
      });

      return menuItems;
    },
});

export const dataSummaryDetailsStateSelector = selectorFamily({
  key: PREFIX + 'DataSummaryDetailsState',
  get:
    (datasetId) =>
    ({ get }) => {
      const dataset = get(datasetQuery(datasetId));
      const {
        assetClasses,
        dataTopics,
        historicalDataStartDate,
        updateFrequency,
        description,
      } = dataset;

      return {
        assetClasses,
        dataTopics,
        historicalDataStartDate,
        updateFrequency,
        description,
      };
    },
});

interface WishlistSelectorParams {
  organizationId: number;
  rand?: number;
}

export const wishlistSelector = refetchableFamily<
  T.DatasetWishItem[],
  Readonly<WishlistSelectorParams>
>({
  key: PREFIX + 'WISHLIST-SELECTOR',
  get:
    ({ organizationId }) =>
    async () => {
      if (!organizationId) {
        return [];
      }

      const url = `/api/v1/organizations/${organizationId}/datasets-wish-items`;
      const { data: whishlist } = await axios.get<T.DatasetWishItem[]>(url);

      return whishlist;
    },
});

export const dashboardSelector = selectorFamily<string | null, string>({
  key: PREFIX + 'DASHBOARD-SELECTOR',
  get: (dashboardSlug) => async () => {
    const url = `/api/v1/dashboard_embed/${dashboardSlug}`;
    const response = await axios.get<{ url: string }>(url);
    return response.data.url;
  },
});

export const isDatasetInWishlistAtom = atomFamily<boolean, string>({
  key: PREFIX + 'IS-DATASET-IN-WISHLIST-ATOM',
  default: selectorFamily({
    key: PREFIX + 'IS-DATASET-IN-WISHLIST-SELECTOR',
    get:
      (id) =>
      ({ get }) => {
        const dataset = get(datasetSelector(id));
        const wishlist = get(
          wishlistSelector({ organizationId: dataset.organizationId })
        );
        return wishlist.some((wishlistItem) => wishlistItem.datasetId === id);
      },
  }),
});
