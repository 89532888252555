import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback } from 'react';
import * as Components from './components';
import withTheme from '../../theme';
import { IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useDispatch } from 'react-redux';
import { useRecoilState, useRecoilValue } from 'recoil';
import { push } from 'connected-react-router';
import * as atoms from './recoil';
import {
  useGreyBody,
  useAnalyticsEffect,
  useAnalyticsCallback,
} from '../../utils';
import axios from 'axios';
import { Dataset } from '../../types';
import showRequestError from 'src/utils/showRequestError';
import { RouteComponentProps } from 'react-router';
import {
  showSuccessPopupNotification,
  showErrorPopupNotification,
} from 'src/products/shell/Notifications';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    padding: '43px 84px 43px 20px',
    gridTemplateAreas: `
      "back header"
      ". content"
    `,
    gridTemplateColumns: 'auto 1fr',
    gap: '40px',
    alignItems: 'flex-start',
  },
  back: {
    gridArea: 'back',
  },
}));

interface DatasetDetailsParams {
  dataset_id: string;
}

type DatasetDetailsProps = RouteComponentProps<DatasetDetailsParams>;

const DatasetDetails: React.FC<DatasetDetailsProps> = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataset = useRecoilValue(atoms.datasetQuery(match.params.dataset_id));
  const dataViewColumns = useRecoilValue(
    atoms.dataViewSelector(match.params.dataset_id)
  );

  const [isWishlistRequestInProgress, setIsWishlistRequestInProgress] =
    React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [isDatasetInWishlist, setIsDatasetInWishlist] = useRecoilState(
    atoms.isDatasetInWishlistAtom(match.params.dataset_id)
  );

  useGreyBody();
  useAnalyticsEffect((analytics) => {
    analytics.track('dataset_viewed', {
      datasetName: dataset.name,
      datasetProvider: dataset.organization.name,
    });
  });

  const trackWishlistAction = useAnalyticsCallback<{ event: string }>(
    (analytics) => {
      return (params) => {
        analytics.track(params.event, {
          datasetName: dataset.name,
          datasetProvider: dataset.organization.name,
        });
      };
    },
    [dataset.id]
  );

  const addToWishlist = useCallback(async () => {
    const url = `/api/v1/organizations/${dataset.organizationId}/datasets/${dataset.id}/add-to-wishlist`;
    try {
      setIsWishlistRequestInProgress(true);
      await axios.post<Dataset>(url);
      trackWishlistAction({ event: 'dataset_added_to_wishlist' });
      setIsDatasetInWishlist(true);
      showSuccessPopupNotification(
        'Thank you for your interest in this dataset. Someone will be in touch shortly with the next steps.'
      );
    } catch (e) {
      showRequestError(e, enqueueSnackbar);
    } finally {
      setIsWishlistRequestInProgress(false);
      showErrorPopupNotification(
        'Sorry. There was an error requesting this dataset.'
      );
    }
  }, [dataset.organizationId, dataset.id]);

  const removeFromWishlist = useCallback(async () => {
    const url = `/api/v1/organizations/${dataset.organizationId}/datasets/${dataset.id}/remove-from-wishlist`;
    try {
      setIsWishlistRequestInProgress(true);
      await axios.post<Dataset>(url);
      trackWishlistAction({ event: 'dataset_removed_wishlist' });
      setIsDatasetInWishlist(false);
    } catch (e) {
      showRequestError(e, enqueueSnackbar);
    } finally {
      setIsWishlistRequestInProgress(false);
    }
  }, [dataset.organizationId, dataset.id]);

  return (
    <div className={classes.container}>
      <IconButton
        aria-label='back'
        className={classes.back}
        size='small'
        onClick={() => dispatch(push('/data-connections'))}
      >
        <ArrowBack color='primary' style={{ fontSize: '24px' }} />
      </IconButton>
      <Components.DatasetHeader
        isButtonDisabled={isWishlistRequestInProgress || isDatasetInWishlist}
        buttonAction={isDatasetInWishlist ? removeFromWishlist : addToWishlist}
        buttonText={isDatasetInWishlist ? 'Request Submitted' : 'Request Trial'}
        name={dataset.name}
        imgUrl={dataset.organization.imageUrl}
        partner={dataset.organization.name}
      />
      <Components.DatasetContents
        dictionary={dataViewColumns}
        preview={dataset.dataPreview}
      />
    </div>
  );
};

DatasetDetails.displayName = 'DatasetDetails';

export default withTheme(DatasetDetails);
