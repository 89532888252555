import React from 'react';

interface Size {
  width: number;
  height: number;
}

type OnResize = (size: Size) => void;

export const useWindowSize = (onResize: OnResize) => {
  const onResizeRef = React.useRef(onResize);
  onResizeRef.current = onResize;
  React.useEffect(() => {
    const listener = () => {
      const size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      onResizeRef.current(size);
    };
    window.addEventListener('resize', listener), listener();
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);
};
