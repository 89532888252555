import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRefetchableValueOnMount } from 'src/utils/recoil/refetchable';
import { wishlistSelector } from '../../DatasetDetails/recoil';
import { DatasetCard } from './DatasetCardList';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(265px, 1fr))',
    gap: '20px',
    paddingBottom: '50px',
  },
  title: {
    display: 'grid',
    marginBottom: 20,
    gridTemplateAreas: `
        "text toggleView"
		`,
  },
  titleText: {
    gridArea: 'text',
    margin: 0,
    fontWeight: 600,
  },
  toggleView: {
    gridArea: 'toggleView',
    alignSelf: 'end',
    justifySelf: 'end',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

export const DatasetsWishlist: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);

  const classes = useStyles();

  const organizationId = useSelector(
    (state: any) => state?.user?.profile?.value?.organizationId
  );

  const [wishlist] = useRefetchableValueOnMount(
    wishlistSelector({ organizationId })
  );

  if (wishlist.length === 0) {
    return null;
  }

  return (
    <div>
      <div className={classes.title}>
        <Typography variant='h5' className={classes.titleText}>
          Wishlist
        </Typography>
        <Typography
          onClick={() => setIsVisible(!isVisible)}
          variant='body1'
          className={classes.toggleView}
        >
          {isVisible ? 'Hide' : 'Show'}
        </Typography>
      </div>
      {isVisible ? (
        <div className={classes.container}>
          {wishlist.map((wishlistItem) => (
            <DatasetCard
              key={wishlistItem.dataset.id}
              {...wishlistItem.dataset}
              organization={wishlistItem.organization}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

DatasetsWishlist.displayName = 'DatasetCardList';
