import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as T from 'src/products/data-connections/types';
import { DatasetDictionary } from './DatasetDictionary';
import DatasetSummaryBody from './DatasetSummaryBody';

interface DatasetSummaryProps {
  dictionary: T.CSV | null | undefined;
  preview: T.CSV | null;
}

const useStyles = makeStyles(() => ({
  dot: {
    width: 6,
    height: 6,
    backgroundColor: '#212121',
    borderRadius: '50%',
    flexShrink: 0,
    marginTop: 8,
  },
  summaryContainer: {
    display: 'grid',
    gap: 22,
    maxWidth: 700,
  },
  dataDictionaryTitle: {
    display: 'flex',
  },
}));

export const DatasetSummary: React.FC<DatasetSummaryProps> = ({
  dictionary,
}) => {
  const classes = useStyles();
  return (
    <div>
      <DatasetSummaryBody />
      <div className={classes.dataDictionaryTitle}>
        <Typography variant='h4'>Data Dictionary and Samples</Typography>
      </div>
      <DatasetDictionary dictionary={dictionary} />
    </div>
  );
};

DatasetSummary.displayName = 'DatasetSummary';
