const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');

export function getTextWidth(text: string) {
  if (!context) {
    return 0;
  }

  context.font = '700 13px Montserrat';
  const metrics = context.measureText(text);
  return metrics.width;
}

export function getContentTextWidth(text: string) {
  if (!context) {
    return 0;
  }

  context.font = '500 12px Montserrat';
  const metrics = context.measureText(text);
  return metrics.width;
}

export const getColumnHeaderData = (
  headers: string[] = [],
  rows: Record<string, string>[] = [],
  maxWidth: number
) => {
  return (headers || []).map((header) => {
    // get all the content by the header or column
    const allContentByHeader = (rows || []).map((row) =>
      row && header ? row[`${header}`] : ''
    );
    // get the length of all the content
    const contentLengthByHeader = (allContentByHeader || []).map(
      (content) => getContentTextWidth(content) + 50
    );
    // find the longest piece of content
    const maxContentWidth = Math.max(...contentLengthByHeader);
    const headerWidth = getTextWidth(header) + 50;
    // we want the width to the greatest of these
    const maxHeaderOrContentWidth = Math.max(headerWidth, maxContentWidth);
    const finalWidth = Math.max(maxHeaderOrContentWidth, maxWidth);

    // but not longer than the maxWidth
    return {
      dataKey: header,
      label: header,
      width: finalWidth,
      isSortable: true,
    };
  });
};
