import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import DatasetSummaryDetails from './DatasetSummaryDetails';
import * as atoms from '../../recoil';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const convertToTitle = (detail) => {
  const title = detail?.replace(/[A-Z]/g, (letter) => ` ${letter}`);
  const formattedTitle = `${title[0].toUpperCase()}${title.slice(1)}`;
  return formattedTitle;
};

const formatStingWithSemicolon = (detail) => {
  return detail?.replace(/;/g, `, `);
};

const getDetailsArray = (details) => {
  const detailsKeys = Object.keys(details);
  const descriptionIndex = detailsKeys.indexOf('description');
  detailsKeys.splice(descriptionIndex, 1);

  const detailsArray = detailsKeys.map((detail) => {
    if (detail === 'historicalDataStartDate' && details[detail] !== null) {
      const date = details[detail].split('T')[0];
      const formattedDate = moment(date).format('MMMM Do YYYY');
      return {
        title: convertToTitle(detail),
        content: formattedDate,
      };
    }

    if (detail === 'dataTopics' || detail === 'assetClasses') {
      const value = details[detail];
      return {
        title: convertToTitle(detail),
        content: formatStingWithSemicolon(value),
      };
    }

    return {
      title: convertToTitle(detail),
      content: details[detail],
    };
  });

  return detailsArray;
};

interface DatasetSummaryBodyParams {
  dataset_id: string;
}

const DatasetSummaryBody: React.FC = () => {
  const params = useParams<DatasetSummaryBodyParams>();

  const details = useRecoilValue(
    atoms.dataSummaryDetailsStateSelector(params?.dataset_id)
  );

  const detailsArray = useMemo(
    () => getDetailsArray(details),
    [params?.dataset_id]
  );

  return (
    <div id='js-data-connections-dataset-details-summary-body'>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant='h4'>Summary</Typography>
          <Typography
            id='js-data-connections-dataset-details-summary-description'
            variant='body1'
          >
            <p dangerouslySetInnerHTML={{ __html: details?.description }} />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h4'>Details</Typography>
          <DatasetSummaryDetails details={detailsArray} />
        </Grid>
      </Grid>
    </div>
  );
};

DatasetSummaryBody.displayName = 'DatasetSummaryBody';

export default DatasetSummaryBody;
