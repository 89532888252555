import React from 'react';

export const useGreyBody = () =>
  React.useLayoutEffect(() => {
    const oldColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = '#f5f5f5';
    return () => {
      document.body.style.backgroundColor = oldColor;
    };
  }, []);
