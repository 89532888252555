import React from 'react';
import PagedTable from 'src/products/data-connections/components/PagedTable';
import * as T from 'src/products/data-connections/types';
import {
  getColumnHeaderData,
  DATA_TABLES_ROW_MINIMUM,
} from 'src/products/data-connections/utils';
import DataDictionarySampleHeader from './DataDictionarySampleHeader';

interface DatasetDictionaryProps {
  dictionary: T.CSV | null | undefined;
}

const ROW_KEY = 'row_number';

export const DatasetDictionary: React.FC<DatasetDictionaryProps> = ({
  dictionary,
}) => {
  const headers = dictionary?.headers || [];
  const rows = dictionary?.rows || [];

  const [search, setSearch] = React.useState('');

  const _dictionary = React.useMemo(() => {
    return (dictionary?.rows || [])
      .filter((row) => {
        if (!search) {
          return true;
        }
        return Object.entries(row).some(([, value]) =>
          value.toLowerCase().includes(search)
        );
      })
      .map((row, index) => ({
        row_number: index,
        ...row,
      }));
  }, [dictionary, search]);

  const columns = React.useMemo(() => {
    return [...getColumnHeaderData(headers, rows, 350)];
  }, [dictionary]);

  return (
    <>
      <DataDictionarySampleHeader search={search} setSearch={setSearch} />
      <PagedTable
        columns={columns}
        items={_dictionary}
        rowKey={ROW_KEY}
        initialRowsPerPage={Math.min(rows.length, DATA_TABLES_ROW_MINIMUM)}
      />
    </>
  );
};

DatasetDictionary.displayName = 'DatasetDictionary';
