import { makeStyles } from '@material-ui/core';
import React from 'react';
import Iframe from 'react-iframe';
import { useRecoilValue } from 'recoil';
import { Dashboard } from 'src/products/data-connections/types';
import {
  useAnalyticsEffect,
  useWindowSize,
} from 'src/products/data-connections/utils';
import { dashboardSelector } from '../recoil';

interface DatasetDashboardProps {
  dashboard: Dashboard;
}

const useStyles = makeStyles(() => ({
  container: {
    margin: '0px -84px',
    backgroundColor: '#fff',
  },
}));

export const DatasetDashboard: React.FC<DatasetDashboardProps> = (props) => {
  const embed_url = useRecoilValue(dashboardSelector(props.dashboard.slug));
  const classes = useStyles();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState(0);
  useWindowSize((size) => {
    const rect = containerRef.current?.getBoundingClientRect();
    if (rect) {
      setHeight(size.height - rect.y - 43);
    }
  });
  useAnalyticsEffect(
    (analytics) => {
      if (!embed_url) {
        return;
      }
      analytics.track('Reporting and Insights Page Clicked', {
        dashboardSlug: props.dashboard.slug,
        dashboardName: props.dashboard.name,
      });
    },
    [embed_url]
  );
  if (!embed_url) {
    return null;
  }
  return (
    <div
      id='js-data-connections-dataset-details-dashboard'
      ref={containerRef}
      className={classes.container}
    >
      <Iframe
        frameBorder={0}
        url={embed_url}
        width='100%'
        overflow='visible'
        height={`${height}px`}
        id='js-cherre-dashboard-embed'
        display='block'
        position='relative'
      />
    </div>
  );
};

DatasetDashboard.displayName = 'DatasetDashboard';
